html,
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

[data-arrow="left"],
[data-arrow="right"] {
    bottom: 0;
    height: 42px;
    margin-bottom: 24px;
    margin-top: auto;
    width: 39px;
    position: fixed;
    margin-left: 160px;
    opacity: 1;
    cursor: pointer;
    z-index: 999;
}

[data-arrow="left"] {
    background: linear-gradient(90deg,
            var(--chakra-colors-brand-500) 10%,
            hsla(0, 0%, 100%, 0));
    left: 0;
}

[data-arrow="right"] {
    background: linear-gradient(270deg,
            var(--chakra-colors-brand-500) 10%,
            hsla(0, 0%, 100%, 0));
    right: 0;
}

[data-arrow="left"]>button {
    background: url("../../public/icons/arrow.png") 50% no-repeat;
    border: none;
    cursor: pointer;
    height: 40px;
    /* margin-left: 150px; */
    width: 100%;
    outline: 0;
    -webkit-transform: rotate(180deg) scale(0.7);
    transform: rotate(180deg) scale(0.7);
}

[data-arrow="right"]>button {
    background: url("../../public/icons/arrow.png") 50% no-repeat;
    border: none;
    cursor: pointer;
    height: 40px;
    width: 100%;
    outline: 0;
    position: absolute;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
}

.menu-slider {
    padding-right: 4.25rem;
}

.tableMenu {
    .ant-table-cell-ellipsis {
        text-align: center;
    }

    .ant-table-body .ant-table-cell:not(.ant-table-cell-with-append) {
        text-align: center;
    }

    .ant-table-cell.ant-table-cell-ellipsis {
        text-align: center;
    }
}